<template>
  <div id="apps">
   <div class="tytuihuo clear">
     <p class="p">平台已经同意退货，请及时填写退货单号</p>
     <p class="p pp">退款金额：￥39.00元</p>
     <p class="p ps">温馨提示：平台已经同意退货，请保证商品完好，没损坏，请及时
   填写快递单号，平台收到货后，会在1-2天内退到您的支付账户</p>
  <div class="stapes clear">
    <p class="p">退货步骤：<br/>
1、寄回商品，并记录快递单号<br/>
2、及时填写快递单号<br/>
3、平台收到商品，验证无误<br/>
4、退款到买家支付账户</p>
  </div>
  <div class="stapes clear">
    <p class="p">退回地址：北京市朝阳区富华大厦A座908室<br/>
收件人：徐斌<br/>
联系电话：15210364599<br/>
商家留言：请在7天内寄回，不要邮寄到付，如超过7天<br/>
未寄回，将不再提供退款功能</p>
  </div>
  <div class="liry clear">
    <img src="../assets/timg.jpeg" class="img1"/>
    <div class="ggg">
       <p>小狗机器人</p>
       <p>订单金额：￥6.9元</p>
    </div>
    <img src="../assets/arr1.png" class="img2"/>
  </div>
  <p class="p">订单编号：11111111111111111111111111111<br/>
退款原因：多拍，错拍<br/>
申请时间：2020-12-01 09：24：50</p>
   </div>
   <div class="buttm clear">
     <span>填写快递单号</span><span>取消退款申请</span><span>查看物流</span>
   </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  components:{
  },
   data () {
      return {

      }
  },
  methods:{
  }
}
</script>
<style>
  .clear{clear:both;overflow: hidden;}
  *{margin:0;padding:0;}
  li{list-style-type: none;}
  body{background:#fff;height: 100vh;}
  .liry{width:100%;border-bottom:solid 1px rgba(187, 187, 187, 100);}
  .liry .img1{width:15%;display: inline-block;float: left;margin:0 4% 4% 4%;}
  .liry .img2{width:8%;display: inline-block;float: right;margin:4% 4% 0 0;}
  .tytuihuo{padding-bottom:70px;}
  .liry .ggg{float: left;}
  .liry .ggg p{color: rgba(16, 16, 16, 100);
font-size: 12px;margin-bottom:3%;
font-family: 方正准圆-标准;}
 .tytuihuo .p{color: rgba(16, 16, 16, 100);
font-size: 13px;display: block;line-height:23px;margin:3% 4%;
font-family: 方正准圆-标准;}
.tytuihuo .pp{font-size:12px;}
.tytuihuo .ps{color: rgba(189, 189, 189, 100);font-size:12px;line-height:20px;}
.tytuihuo .stapes{border-radius: 5px;
background-color: rgba(238, 238, 238, 100);
border: 1px solid rgba(255, 255, 255, 100);width:92%;margin:0 auto 5% auto;}
.buttm{position: fixed;bottom:0;left:0;width:100%;background: #fff;border-top:solid 1px rgba(187, 187, 187, 100);}
.buttm span{display: inline-block;border-radius: 4px;
color: rgba(16, 16, 16, 100);
font-size: 12px;padding:4px 3%;float: right;
text-align: center;
font-family: PingFang SC;margin:3% 4% 3% 0;
border: 1px solid rgba(187, 187, 187, 100);}
</style>
